<template>
    <q-layout class="com-login" view="hHh lpR fFf">
        <q-page-container>
            <div style="padding:50px;text-align:center">
                <div style="display:inline-block;width:400px;text-align:left">
                    <div style="text-align:center">
                        <!--<img src="/image/admin/login-icon-157x71.png" >-->
                        <div class="ft20 my3 py3" style="height:42px">
                            {{center_info.center_name}}
                        </div>
                    </div>
                    <q-card>
                        <q-card-section>
                            <div class="ft20 text-center" >
                                <q-icon name="login" ></q-icon> 로그인
                            </div>
                        </q-card-section>
                        <q-separator></q-separator>
                        
                        <q-card-section >
                            <div class="text-center">
                                <div class="text-bold mb3">학원업무 쉽게 해결하세요.</div>
                                <div class="q-mb-md" >대시보드에서 다양한 기능을 사용할 수 있습니다.</div>
                            </div>
                            <com-data-form :form="form" ref="data_form">
                                <template v-slot="props">
                                    <q-input outlined placeholder="아이디" 
                                            :error="(props.formError.admin_name)?true:false" 
                                            :error-message="props.formError.admin_name" 
                                            @keyup.enter="handle_login"
                                            v-model="form.admin_name" >
                                        <template v-slot:prepend>
                                            <q-icon name="perm_identity"></q-icon>
                                        </template>
                                    </q-input>
                                    <q-input outlined placeholder="비밀번호" :type="is_password ? 'password':'text'" 
                                            :error="(props.formError.passwd)?true:false" 
                                            :error-message="props.formError.passwd" 
                                            @keyup.enter="handle_login"
                                            v-model="form.passwd" >
                                        <template v-slot:prepend>
                                            <q-icon name="lock" ></q-icon>
                                        </template>
                                        <template v-slot:append>
                                            <q-icon :name="is_password ? 'visibility_off':'visibility'"
                                                    class="cursor-pointer" @click="is_password=!is_password" ></q-icon>
                                        </template>
                                    </q-input>
                                    <q-space></q-space>
                                    <q-btn type="submit" color="positive" class="full-width" label="로그인"
                                            @click="handle_login" ></q-btn>
                                </template>
                            </com-data-form>
                        </q-card-section>
                        <q-separator ></q-separator>
                        
                        <q-card-section class="text-right">
                                아이디가 없다면, 관리자에 문의하세요.
                        </q-card-section>
                    </q-card>
                    
                </div>
            </div>
        </q-page-container>
    </q-layout>
</template>

<script>
import comDataForm from '@/components/comDataForm.vue';

export default {
    components : {
        comDataForm,
    },
    data : function() {
        return {
            form : {
                host : '',
                admin_name : '',
                passwd : '',
            },
            is_password : true,
        }
    },
    computed :{
        center_info() {
            return this.$store.state.center_info;
        },
    },
    methods : {
        handle_login() {
            let vm = this;
            vm.$q.loading.show();
            vm.$refs.data_form.clear_error();
            vm.$c.io.post('/api/account/admin/login', this.form).then(function(response) {
                let data = response.data;
                if(data.success) {
                    window.localStorage.setItem('admin_name', vm.form.admin_name);
                    //window.localStorage.setItem('password', vm.form.passwd);
                    vm.$c.io.get('/api/account/admin/info', {}).then(function(response) {
                        let data = response.data;
                        if(data.success) {
                            vm.$store.state.admin_id = data.admin_prof.admin_id;
                            vm.$store.state.admin.a_name = data.admin_prof.a_name;
                            vm.$store.state.admin.admin_group_id = data.admin_prof.admin_group_id;
                            vm.$store.state.admin.admin_id = data.admin_prof.admin_id;
                            vm.$store.state.admin.admin_name = data.admin_prof.admin_name;
                            vm.$c.goto_page('/admin/app/aca_erp/staff/home');
                            return;
                        }
                    });
                } else {
                    vm.$q.loading.hide();
                    vm.$c.form.set_error(data.error, vm.$refs.data_form.formError);
                    if( data.message ) {
                        vm.$c.response_notify(0, data.message);
                    }
                }
            });
        },
    },
    beforeMount() {
        let host = window.localStorage.getItem('host');
        let admin_name = window.localStorage.getItem('admin_name');
        //let passwd = window.localStorage.getItem('password');
        if(host) { this.form.host = host; }
        if(admin_name) { this.form.admin_name = admin_name; }
        //if(passwd) { this.form.passwd = passwd; }
    }
}
</script>

